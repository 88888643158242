@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Changa:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.cdnfonts.com/css/chopin-script);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Ant Design CSS */
/* "Lato" font */
/* "Changa" font */
/* "Chopin" font */


@media (prefers-color-scheme: light){
  :root{
    --background: #FCF0F4;
    --base: #F7D4E0;
    --secondary: #6F4653;
    --primary: #DE8CA7;
    --text: #000000;
  }
}

@media (prefers-color-scheme: dark){
  :root{
    --background: #1F1C1C;
    /* --base: #60142A; */
    /* --secondary: #C12854; */
    /* --primary: #F23269; */
    --base: #751a52;
    --primary: #FF66C4;
    --secondary: #b43985;
    --text: #E9E8EA;
  }
}

body {
  background-color: var(--background);
  color: var(--text);
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p{
  color: var(--text);
}

h1,
h2,
h3,
h4,
h5,
h6,
.title{
  font-family: 'Chopin Script', sans-serif;
  font-weight: 400;
}

a{
  color: var(--primary);
}

a:hover{
  color: var(--secondary);
}

/**** Generals ****/
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.btn-primary{
  background-color: var(--primary);
  border: 0;
  border-radius: 7px;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover{
  background-color: var(--secondary);
}

.btn-secondary{
  background-color: var(--background);
  border: 1px solid var(--primary);
  border-radius: 7px;
  color: var(--primary);
}

.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover{
  border-color: var(--secondary);
  background-color: var(--background);
  color: var(--secondary);
}

.img-background{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.sheer-layer{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 50px;
}

@media (max-width: 991px){
  .sheer-layer{
    font-size: 30px;
  }
}

@media (max-width: 575px){
  .sheer-layer{
    font-size: 20px;
  }
}

.img-fluid {
  max-width: 100%;
}
.img-fluid[width] {
  width: auto; /* Defer to max-width */
}
.img-fluid[width][height] {
  height: auto; /* Preserve aspect ratio */
}

.img-fluid[src$=".svg"] {
  width: 100%;
  height: auto;
  max-width: none;
}

.img-frame::before{
  content: "";
  position: absolute;
  top: -40px;
  left: -40px;
  width: 100%;
  height: 100%;
  border: 4px solid var(--primary);
  z-index: -1;
}

.mt-fluid{
  margin-top: 100px;
}

.btn-transparent{
  background-color: transparent;
  border: 0;
  color: var(--primary);
}

@media (max-width: 992px){
  .img-frame::before{
    top: -20px;
    left: -20px;
  }
}

@media (max-width: 975px){
  .mt-fluid{
    margin-top: 40px;
  }
}

/**** Menu.tsx ****/
.main-menu-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

.main-menu-lg{
  display: none;
}

.main-menu-md{
  display: none;
}

.main-menu{
  width: 100%;
  border-bottom: 0;
}

.main-menu-logo{
  object-fit: contain;
  width: 200px;
  margin-left: 20px;
}

.main-menu-button{
  padding: 8px 10px;
  font-size: 16px;
  font-weight: 500;
  color: var(--text);
}

.main-menu-button:hover{
  color: var(--primary);
}

.main-menu-button-highlight{
  color: var(--primary);
  font-weight: 500;
}

.main-menu-hr{
  height: .25rem;
  margin: 0;
  margin-top: 5px;
  border: none;
  background-color: var(--primary);
  transition: .3s ease-in-out;
}

.main-menu-mobile{
  background-color: var(--background);
}

.ant-drawer-header-title{
  justify-content: flex-end;
}

.ant-drawer-close{
  margin: 0;
  padding: 0;
}

.main-menu-button-mobile,
.main-menu-button-mobile:active,
.main-menu-button-mobile:hover{
  margin-bottom: 7px;
  padding-bottom: 3px;
  color: var(--text);
  font-size: 22px;
}

.main-menu-button-mobile-highlight,
.main-menu-button-mobile-highlight:active,
.main-menu-button-mobile-highlight:focus{
  color: var(--primary) !important;
  font-weight: 500;
  border-bottom: .25rem solid var(--primary) !important;
}

@media (min-width: 992px){
  .main-menu-lg{
    display: block;
  }
}

@media (max-width: 991px){
  .main-menu-md{
    display: block;
  }
}

/**** Footer.tsx ****/
.footer-container{
  padding: 10px 0;
  border-top: 1px solid var(--secondary);
}

.footer-content{
  text-align: center;
  color: var(--secondary);
  font-size: 14px;
}

.footer-mail,
.footer-mail:hover{
  color: var(--secondary);
  text-decoration: underline;
}

/**** Featured.tsx ****/
.featured-img{
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

@media (max-width: 991px){
  .featured-img{
    font-size: 30px;
  }
}

@media (max-width: 575px){
  .featured-img{
    font-size: 20px;
  }
}

/**** SectionTitle.tsx ****/
.sectiontitle-h1{
  margin-bottom: 0;
  font-size: 50px;
  line-height: 1;
}

.sectiontitle-h1-alt{
  font-family: 'Lato', sans-serif;
}

.sectiontitle-hr{
  width: 50px;
  margin: 0;
  margin-bottom: 20px;
  padding-bottom: 2px;
  border: none;
  border-bottom: 4px solid var(--primary);
}

/**** Home.tsx ****/
.home-quote-title{
  margin: 7px 0 7px 0;
  line-height: 1;
  text-align: center;
  font-size: 45px;
  font-weight: 500;
}

.home-quote-subtitle{
  margin: 0;
  line-height: 1;
  text-align: center;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
}

@media (max-width: 576px){
  .home-quote-title{
    font-size: 29px;
    line-height: 0.8;
  }

  .home-quote-subtitle{
    font-size: 14px;
  }
}

/**** Biography.tsx ****/
.bio-pic{
  width: 50%;
  float: left;
  margin-bottom: 20px;
  margin-right: 20px;
}

.bio-cv-download{
  padding: 20px;
  font-size: 18px;
}

@media (max-width: 576px){
  .bio-pic{
    width: 100%;
    float: none;
    margin-right: 0;
  }
}


/**** Contacts.tsx ****/
.contacts-input,
.contacts-input:hover{
  border: 1px solid var(--primary);
  border-radius: 7px;
  background-color: var(--base) !important;
  color: var(--text);
}

.contacts-input:focus{
  border-color: var(--primary);
}

.contacts-result{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-result-title{
  color: var(--text);
  font-size: 30px;
}

.ant-result-subtitle{
  color: var(--text);
  font-size: 18px;
}
